<template>
  <div
    class="
      absolute
      bg-red-500
      font-bold
      h-5
      inline-flex
      items-center
      justify-center
      -right-2
      rounded-full
      text-xs
      text-white
      -top-2
      w-5
    "
  >
    <slot />
  </div>
</template>
